export default function useSurveyWallParameters(): [string, string] {
  const urlParams = new URLSearchParams(window.location.search);
  const adSlot = urlParams.get("adSlot");
  const externalIdentifier = urlParams.get("external_identifier");

  if (!adSlot || !externalIdentifier) {
    throw Error("adSlot and external_identifier required");
  }

  return [adSlot, externalIdentifier];
}
