import IconMessage from "../components/iconmessage/IconMessage";
import PageContainer from "../components/page/PageContainer";

export default function ErrorScreen() {
  return (
    <PageContainer scrollBarVisible={false}>
      <IconMessage message="Something went wrong. Please try again." />
    </PageContainer>
  );
}
