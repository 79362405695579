import { z } from "zod";

export const RewardStatusUserSchema = z.object({
  id: z.number(),
  external_identifier: z.string(),
  publisher_id : z.number(),
  publisher_placement_id : z.number()
});

export const TaskSchema = z.object({name: z.string(), support_allowed: z.boolean(),currency_value: z.number(), converted: z.boolean()});

export const RewardStatusOfferSchema = z.object({
  status : z.string(),
  campaign_id: z.number(),
  campaign_type: z.string(),
  name : z.string(),
  platform : z.string(),
  icon : z.string().nullable(),
  support_message : z.string(),
  days_left : z.number(),
  rewarded_amount : z.number(),
  total_reward_amount : z.number(),
  is_support_allowed : z.boolean(),
  landing_page: z.string(),
  tasks : z.array(TaskSchema),
  click_time : z.number(),
  installation_time : z.number(),
  short_instruction : z.string(),
  long_instruction : z.string(),
  currency_singular : z.string(),
  currency_plural : z.string()
});

export const RewardStatusOfferListSchema = z.array(RewardStatusOfferSchema);


export const RewardStatusResponseSchema = z.object({
  user : RewardStatusUserSchema,
  reward_status : RewardStatusOfferListSchema,
});

export type RewardStatusUser = z.infer<typeof RewardStatusUserSchema>;
export type RewardStatusOffer = z.infer<typeof RewardStatusOfferSchema>;
export type RewardStatusOfferList = z.infer<typeof RewardStatusOfferListSchema>;
export type RewardStatusResponse = z.infer<typeof RewardStatusResponseSchema>;
