import Spinner from "../spinner/Spinner";
import styles from "./Loading.module.scss";

const Loading = () => {
  return (
    <div className={styles.container}>
      <h2>
        Page is loading, <strong>please wait...</strong>
      </h2>
      <div>
        <Spinner />
      </div>
    </div>
  );
};

export default Loading;
