import PageContainer from "../../components/page/PageContainer";
import PrivacyPolicy from "../../components/privacypolicy/PrivacyPolicy";
import { useStickyHeaderScrollForward } from "../../hooks/useStickyHeaderScrollForward";

const PrivacyPolicyScreen = () => {
  const scrollListener = useStickyHeaderScrollForward();
  return (
    <PageContainer scrollListener={scrollListener}>
      <PrivacyPolicy />
    </PageContainer>
  );
};

export default PrivacyPolicyScreen;
