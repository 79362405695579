import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.scss";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPageGuard from "./screens/landingpage/LandingPageGuard";
import LandingPage from "./screens/landingpage/LandingPage";
import Root from "./Root";
import { PrivacyPolicyGuard } from "./screens/privacypolicy/PrivacyPolicyScreenGuard";
import PrivacyPolicyScreen from "./screens/privacypolicy/PrivacyPolicyScreen";

import ErrorScreen from "./screens/ErrorScreen";
import NotFoundScreen from "./screens/NotFoundScreen";

import RewardStatusOfferDetails from "./screens/rewardstatusofferdetails/RewardStatusOfferDetails";
import RewardStatusSubmitTicket from "./screens/rewardstatussubmitticket/RewardStatusSubmitTicket";
import RewardStatusOffers from "./screens/rewardstatusoffers/RewardStatusOffers";
import RewardStatusSubmitted from "./screens/rewardstatussubmitted/RewardStatusSubmitted";

//TODO: Create proper error screen for root element
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorScreen />,
    children: [
      {
        errorElement: <ErrorScreen />,
        children: [
          {
            index: true,
            element: (
              <LandingPageGuard>
                <LandingPage />
              </LandingPageGuard>
            ),
          },
          {
            path: "/privacypolicy",
            element: (
              <PrivacyPolicyGuard>
                <PrivacyPolicyScreen />
              </PrivacyPolicyGuard>
            ),
          },
          {
            path: "/rewardstatusoffers",
            element: (
              <PrivacyPolicyGuard>
                <RewardStatusOffers />
              </PrivacyPolicyGuard>
            ),
          },
          {
            path: "/rewardstatusofferdetails",
            element: (
              <PrivacyPolicyGuard>
                <RewardStatusOfferDetails />
              </PrivacyPolicyGuard>
            ),
          },
          {
            path: "/rewardstatussubmitticket",
            element: (
              <PrivacyPolicyGuard>
                <RewardStatusSubmitTicket />
              </PrivacyPolicyGuard>
            ),
          },
          {
            path: "/rewardstatussubmitted",
            element: (
              <PrivacyPolicyGuard>
                <RewardStatusSubmitted />
              </PrivacyPolicyGuard>
            ),
          },
          { path: "*", element: <NotFoundScreen /> },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
