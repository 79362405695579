import styles from "./RewardStatusOffers.module.scss";
import React, { useState, useEffect } from "react";
import RewardStatusOfferCard from "../../components/rewardstatusoffercard/RewardStatusOfferCard";
import { useNavigate } from "react-router-dom";
import useRewardStatusParameters from "../../hooks/useRewardStatusParameters";

import useThrowAsyncError from "../../hooks/useThrowAsyncError";

import Loading from "../../components/loading/Loading";

import LupeIcon from "../../images/Lupe.png";

import PageContainer from "../../components/page/PageContainer";
import OfferGrid from "../../components/surveygrid/SurveyGrid";
import { useStickyHeaderScrollForward } from "../../hooks/useStickyHeaderScrollForward";

import RewardStatusHeader from "../../components/rewardstatusheader/RewardStatusHeader";
import { getRewardStatusOffers } from "../../lib/rewardStatusApi";
import { RewardStatusUser, RewardStatusResponse, RewardStatusOfferList, RewardStatusOffer, RewardStatusResponseSchema } from "../../lib/model/rewardstatus";

const RewardStatusOffers = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>("all");
  const [rewardstatusresponse, setRewardStatusResponse] = useState<RewardStatusResponse>();
  const scrollListener = useStickyHeaderScrollForward();

  const throwAsyncError = useThrowAsyncError();

  const [externalIdentifier, adslotId] = useRewardStatusParameters();

  let navigate = useNavigate(); 

  const onChange = (newValue:any) =>  {
    setFilter(newValue.value);
  }

  const navigateToDetails = (user: RewardStatusUser, rewardstatusoffer : RewardStatusOffer) => {
    return navigate('/rewardstatusofferdetails?external_identifier='+externalIdentifier+'&adSlot='+adslotId,{state:{user: user, item:rewardstatusoffer}});
  }

  useEffect(() => {
    getRewardStatusOffers(window, adslotId, externalIdentifier)
          .then((response) => {
            setRewardStatusResponse(response);
            setLoading(false);
          })
          .catch(throwAsyncError);
  }, []);
  
  return (
    <PageContainer scrollBarVisible={!loading} scrollListener={scrollListener}>
          
          <RewardStatusHeader dropdown={true} userId={rewardstatusresponse ? rewardstatusresponse.user.id : 0} dropdownOnChange={onChange} ></RewardStatusHeader>

          {loading ? (
          <Loading />
        ) : (

          rewardstatusresponse!.reward_status.length == 0 ?

        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}><img height={110} src={LupeIcon} ></img><div className={styles.noOffersFirstLine}>We didn’t track any activity for you within the last 180 days.</div><div className={styles.secondLine}>In case of questions, please reach out to the owner of the app or website you are currently using.</div></div>:(

        <OfferGrid>
       { 

        rewardstatusresponse!.reward_status.filter((item:RewardStatusOffer) => item.status == filter || filter == "all").map((item:RewardStatusOffer,index:number) => (
          <RewardStatusOfferCard
          item={item}
          status={item.status}
          onClick={() => {navigateToDetails(rewardstatusresponse!.user,item);}}
        />)
        )

        }
        
        
        
        
        
        </OfferGrid>

        )
        
        )
         

      }
                 
            

          

    </PageContainer>
  );
};

export default RewardStatusOffers;
