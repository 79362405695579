import PageContainer from "../components/page/PageContainer";
import IconMessage from "../components/iconmessage/IconMessage";

export default function NotFoundScreen() {
  return (
    <PageContainer scrollBarVisible={false}>
      <IconMessage message="Sorry this page seems, unavailable..." />
    </PageContainer>
  );
}
