import { v4 as uuidv4 } from "uuid";
import { Survey } from "./model/survey";

const randomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const generateRandomSurveys = (amount: number): Survey[] => {
  return [...Array(amount)].map(() => ({
    id: uuidv4(),
    partner_name: "unknown",
    cpi: "" + randomInt(100, 1000) / 100,
    loi: "" + randomInt(1, 30),
    category: [
      "automobile",
      "beauty",
      "food",
      "education",
      "lifestyle",
      "technology",
    ][randomInt(0, 5)],
    url: "https://www.ayetstudios.com/",
    additional_info: undefined,
    missing_qualifications: [...Array(randomInt(0, 5))].map(() => ""),
    data: null,
  }));
};

export default generateRandomSurveys;
