import { useState } from "react";
import styles from "./Root.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { ContentFrame, generateTheme, Header } from "react-ui-lib";
import { ButtonBase } from "@mui/material";

export default function Root() {
  const navigate = useNavigate();
  const location = useLocation();
  const [headerCollapsed, setHeaderCollapsed] = useState<boolean>(false);

  //TODO: customize with color from config
  const theme = generateTheme("#d45097", "#3b3c3b", "#3b3c3b", "12px");


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <ContentFrame>
            <Header
              collapsed={headerCollapsed}
              showContextIcon={false}
              title="Reward Status"
              drawerTitle="Menu"
              drawerBuilder={(close) => {
                return (
                  <>
                    <ButtonBase
                      className={styles.drawerButton}
                      onClick={() => {
                        navigate({
                          pathname: "/",
                          search: location.search,
                        });
                        close();
                      }}
                    >
                      <h2>
                        <strong>Root</strong>
                      </h2>
                    </ButtonBase>
                  </>
                );
              }}
            />

            <Outlet
              context={{
                scrollListener: (position: number) =>
                  setHeaderCollapsed(position > 0),
              }}
            />
          
        </ContentFrame>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
