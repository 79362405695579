import { CircularProgress } from "@mui/material";
import PrivacyPolicy from "../../../../components/privacypolicy/PrivacyPolicy";
import styles from "./PolicyPopup.module.scss";

const PolicyPopup = ({
  loading,
  rejectPolicies,
  acceptPolicies,
}: {
  loading: boolean;
  rejectPolicies: Function;
  acceptPolicies: Function;
}) => {
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.text}>
          <PrivacyPolicy />
        </div>

        <div className={styles.buttonWrap}>
          <button
            className={styles.cancelButton}
            disabled={loading}
            onClick={() => rejectPolicies()}
          >
            Cancel
          </button>
          <button
            className={styles.acceptButton}
            disabled={loading}
            onClick={() => acceptPolicies()}
          >
            {loading ? (
              <CircularProgress size="24px" color="inherit" />
            ) : (
              "Accept"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyPopup;
