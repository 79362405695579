import styles from "./RewardStatusOfferDetails.module.scss";

import PageContainer from "../../components/page/PageContainer";

import {useLocation, useNavigate} from 'react-router-dom';

import { useStickyHeaderScrollForward } from "../../hooks/useStickyHeaderScrollForward";

import useRewardStatusParameters from "../../hooks/useRewardStatusParameters";

import RewardStatusHeader from "../../components/rewardstatusheader/RewardStatusHeader";

import OfferDescription from "./components/OfferDescription";
import OfferDetails from "./components/OfferDetails";
import OfferStatus from "./components/OfferStatus";
import OfferTasksDetails from "./components/OfferTasksDetails";

import Faq from "./components/Faq";


import { RewardStatusUser, RewardStatusOffer } from "../../lib/model/rewardstatus";

const Offers = () => {

  const [externalIdentifier, adslotId] = useRewardStatusParameters();
  
  const location = useLocation();
  const navigate = useNavigate();
  
  const scrollListener = useStickyHeaderScrollForward();

  const navigateToDetails = (user : RewardStatusUser, rewardstatusoffer : RewardStatusOffer) => {
    return navigate('/rewardstatussubmitticket?external_identifier='+externalIdentifier+'&adSlot='+adslotId,{state:{user: user, item:rewardstatusoffer}});
  }

  const navigateToContinue = (rewardstatusoffer : RewardStatusOffer) => {
    window.location.href = rewardstatusoffer.landing_page;
    return null;
  }


  return (
    <PageContainer scrollBarVisible={false} scrollListener={scrollListener}>
 
          <RewardStatusHeader dropdownOnChange={()=>{}} dropdown={false} userId={location.state.user.id}></RewardStatusHeader>

          <OfferDescription item={location.state.item}></OfferDescription>

          <OfferDetails item={location.state.item}></OfferDetails>

          <OfferStatus item={location.state.item} ></OfferStatus>

          <OfferTasksDetails item={location.state.item} ></OfferTasksDetails>

          <Faq></Faq>

          <div className={styles.buttonBox}><button onClick={() => {navigateToContinue(location.state.item)}} className={styles.continueButton}>Continue Offer</button><button onClick={() => {navigateToDetails(location.state.user,location.state.item)}} className={styles.submitButton}>Submit Ticket</button></div>

    </PageContainer>
  );
};

export default Offers;
