import styles from "./PageContainer.module.scss";
import Logo from "../../images/LogoOriginalColorSmall.png";
import React, { useEffect, useRef } from "react";
import classnames from "classnames";

const PageContainer = ({
  children,
  scrollBarVisible = true,
  scrollListener = () => {},
}: {
  children: React.ReactNode;
  scrollBarVisible?: boolean;
  scrollListener?: (position: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (!ref.current) {
      return;
    }
    scrollListener(ref.current.scrollTop);
  };
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className={styles.main}>
      <div
        className={classnames(
          {
            [styles.hiddenScroll]: !scrollBarVisible,
          },
          styles.content
        )}
        ref={ref}
      >
        <div className={styles.mainContent}>{children}</div>
        <div className={styles.footer}>
          <img alt="ayetstudios" src={Logo} />
        </div>
      </div>
      {scrollBarVisible && <div className={styles.scrollSpacer} />}
    </div>
  );
};

export default PageContainer;
