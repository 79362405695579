import styles from "./OfferDetails.module.scss";

import tasksIcon from "../../../images/TotalTasks.png";
import coinsIcon from "../../../images/Coins.png";
import daysLeftIcon from "../../../images/DaysLeft.png";
import { RewardStatusOffer } from "../../../lib/model/rewardstatus";

const OfferDetails = ({ item }: { item: RewardStatusOffer }) => {
  return (
    <div>
      <div className={styles.title}>Offer Details</div>
      <div className={styles.detailsBox}>
        <div className={styles.titleDesc}>{item.support_message}</div>
        <div className={styles.propertiesBox}>
          <div className={styles.property}>
            <img className={styles.propertyImg} src={tasksIcon}></img>
            <div className={styles.propertyCount}>{item.tasks.length}</div>
            <div className={styles.propertyText}>Total Tasks</div>
          </div>
          <div className={styles.property}>
            <img className={styles.propertyImg} src={coinsIcon}></img>
            <div className={styles.propertyCount}>{item.rewarded_amount} {item.currency_plural}</div>
            <div className={styles.propertyText}>Total Rewards</div>
          </div>
          <div className={styles.property}>
            <img className={styles.propertyImg} src={daysLeftIcon}></img>
            <div className={styles.propertyCount}>{item.days_left}</div>
            <div className={styles.propertyText}>Days Left</div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default OfferDetails;
