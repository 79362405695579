import styles from "./Faq.module.scss";

import { Accordion } from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  return (
    <div style={{marginTop: "50px", marginBottom: "60px"}}>
      <div className={styles.title}>FAQ</div>
    
      <Accordion disableGutters={true} sx={{'&:before': {
                display: 'none',
            }, fontSize: '12px', borderBottom: '1px solid #dddddd', boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={styles.faqQuestion}>I've completed the offer but have not received any compensation. What should I do?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={styles.faqAnswer}>
          There are a few possible reasons for not receiving your rewards. Please read the below before submitting your ticket:<br />
          <br />
Incomplete offer requirements: <br />
You did not meet the offer requirements necessary to be awarded. Please review the offer instructions carefully and ensure that you have completed all the steps.<br />
The offer required you to validate your email address.<br />
You completed the offer through another device or source. All offers must be completed through ayeT-Studios' offerwall to be eligible.<br />
You had the promoted app installed previously.<br />
You installed the promoted app through another provider prior to completing it through ayeT-Studios.<br />
You are using multiple devices. Only one transaction per household is allowed.<br />
You installed a promoted app, but did not launch it.<br />
You installed a promoted app, but did not reach the conversion event.<br />
 Technical Reasons: <br />
You did not have a working data connection when you downloaded a promoted app.<br />
You reset your device ID too many times.<br />
You were using a VPN while completing the offer.
          </Typography>
        </AccordionDetails>
        </Accordion>
        <Accordion disableGutters={true} sx={{'&:before': {
                display: 'none',
            }, borderBottom: '1px solid #dddddd', boxShadow: "none" }}>  
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={styles.faqQuestion}>What is proof and what do I need to submit?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={styles.faqAnswer}>
          Proof is evidence that you completed the offer and helps us or the Advertiser to verify your eligibility. Please save all proof showing that you completed the offer to your records. This may include screenshots of your progress, welcome e-mails, payment receipts, registration, order numbers, invoices, etc. Each offer requires its own proof based on the offer instructions. Be sure to read the offer instructions so that valid proof is submitted. Please review the offer instructions and bellow before submitting the information requested based on offer type:<br />
          Product/Service Purchase or Registration<br />
          If you purchased a product or service, please provide a screenshot of the confirmation email, billing invoice, or welcome email you received when you completed the offer. This will allow the Advertiser to look up your records and verify your eligibility.<br />
 App Download/Engagement<br />
If you downloaded an app, please ensure you've run the app for at least 2 minutes and provide a screenshot of the app running on your device.<br />
If you downloaded an app and completed an action, provide screenshots showing that you have completed the action.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters={true} sx={{'&:before': {
                display: 'none',
            }, borderBottom: 'none', boxShadow: "none" }}>  
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={styles.faqQuestion}>Did I comply with the reward conditions?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={styles.faqAnswer}>
          Proof is evidence that you completed the offer and helps us or the Advertiser to verify your eligibility. Please save all proof showing that you completed the offer to your records. This may include screenshots of your progress, welcome e-mails, payment receipts, registration, order numbers, invoices, etc. Each offer requires its own proof based on the offer instructions. Be sure to read the offer instructions so that valid proof is submitted. Please review the offer instructions and bellow before submitting the information requested based on offer type:<br />
          Product/Service Purchase or Registration<br />
          If you purchased a product or service, please provide a screenshot of the confirmation email, billing invoice, or welcome email you received when you completed the offer. This will allow the Advertiser to look up your records and verify your eligibility.<br />
 App Download/Engagement<br />
If you downloaded an app, please ensure you've run the app for at least 2 minutes and provide a screenshot of the app running on your device.<br />
If you downloaded an app and completed an action, provide screenshots showing that you have completed the action.
          </Typography>
        </AccordionDetails>
      </Accordion>
     

    </div>
  );
};

export default Faq;
