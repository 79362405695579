import axios from "axios";
import { CompletionRequiredSchema } from "./model/completionRequired";
import { Question, QuestionListSchema } from "./model/question";
import { Survey, SurveyListSchema } from "./model/survey";
import { isJwtValid } from "./tokenUtils";

const adSlotStorageKey: string = "adSlot";
const externalIdentifierStorageKey: string = "externalIdentifier";
const userTokenLocalStorageKey: string = "token";
const deviceTokenLocalStorageKey: string = "deviceToken";

/**
 * Authenticate api requests
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 * @returns user_token
 */
export const authenticate = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string
): Promise<string> => {
  //Try to retrieve tokens from local storage
  let savedUserToken = window.localStorage.getItem(userTokenLocalStorageKey);
  let savedDeviceToken = window.localStorage.getItem(
    deviceTokenLocalStorageKey
  );

  //Clear tokens if adSlot / externalIdentifier changed
  const savedAdSlot = window.localStorage.getItem(adSlotStorageKey);
  const savedExternalIdentifier = window.localStorage.getItem(
    externalIdentifierStorageKey
  );
  if (
    savedAdSlot !== adSlot ||
    savedExternalIdentifier !== externalIdentifier
  ) {
    savedUserToken = null;
    savedDeviceToken = null;
  }

  //UserToken is saved
  if (savedUserToken) {
    //Check if token is expired
    if (isJwtValid(savedUserToken)) {
      //Token is not expired, so we can still use it
      return savedUserToken;
    }
  }

  //Authenticate device again if there is no device token or device token is expired
  if (!savedDeviceToken || !isJwtValid(savedDeviceToken)) {
    const identifyBody: Record<string, any> = {
      useragent: window.navigator.userAgent,
    };
    if (savedDeviceToken) {
      identifyBody.device_token = savedDeviceToken;
    }
    const identifyResponse = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/devices/identify?XDEBUG_SESSION_START=PHPSTORM`,
      identifyBody
    );
    savedDeviceToken = identifyResponse.data.deviceToken;
  }

  //TODO: Handle adSlot config
  const adSlotResponse = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/crud/adslots/${adSlot}`
  );

  const userIdentifyResponse = await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/users/identify/${adSlot}?external_identifier=${externalIdentifier}`,
    {
      device_token: savedDeviceToken!,
    }
  );

  if (userIdentifyResponse.data.db_write_required) {
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/rest/v1/devices/update`, {
      external_identifier: externalIdentifier,
      device_token: savedDeviceToken!,
      useragent: window.navigator.userAgent,
      force_db_update: true,
    });
  }

  //Save tokens after successful authentication
  window.localStorage.setItem(deviceTokenLocalStorageKey, savedDeviceToken!);
  window.localStorage.setItem(
    userTokenLocalStorageKey,
    userIdentifyResponse.data.user_token
  );
  window.localStorage.setItem(adSlotStorageKey, adSlot);
  window.localStorage.setItem(externalIdentifierStorageKey, externalIdentifier);

  return userIdentifyResponse.data.user_token;
};

/**
 * Read all available surveys
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 * @returns
 */
export const getSurveys = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string
): Promise<Survey[]> => {
  const token = await authenticate(window, adSlot, externalIdentifier);
  const response = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/surveys/${adSlot}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const surveyList = SurveyListSchema.parse(response.data);
  //TODO: remove limit surveyList to 20 entries
  return surveyList.slice(undefined, Math.min(20, surveyList.length));
};

/**
 * Check if profiler is available
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 * @returns
 */
export const getProfilerCompletionRequired = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string
): Promise<boolean> => {
  const token = await authenticate(window, adSlot, externalIdentifier);
  const response = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/profilerAnswers/profilerRequired/`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  const completionRequired = CompletionRequiredSchema.parse(response.data);
  return completionRequired.completion_required;
};

/**
 * Retrieve all missing (profile) profiler questions
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 */
export const getMissingProfilerQuestions = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string
): Promise<Question[]> => {
  const token = await authenticate(window, adSlot, externalIdentifier);
  const response = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/surveyProfilers/?missing_only=true`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  const questionList = QuestionListSchema.parse(response.data);
  return questionList;
};

/**
 * Save (profile) profiler answers
 *
 * TODO: Use typescripts type system
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 * @param answers
 * @returns
 */
export const saveProfilingQuestions = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string,
  answers: any
): Promise<any> => {
  const token = await authenticate(window, adSlot, externalIdentifier);
  const response = await axios.put(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/profilerAnswers/${adSlot}`,
    { answers: answers },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

/**
 * Get External Profiler Question By Question Key
 *
 * TODO: Use typescripts type system. Question type is different to getMissingProfilerQuestions
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 * @param partnerName
 * @param questionIds
 * @returns
 */
export const getExternalQuestions = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string,
  partnerName: any,
  questionIds: any
): Promise<any> => {
  const token = await authenticate(window, adSlot, externalIdentifier);
  const response = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/surveyProfilers/externalQuestions/${partnerName}/${questionIds}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};
