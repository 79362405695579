import { Navigate, useLocation } from "react-router";

export const PrivacyPolicyGuard = ({ children }: { children: JSX.Element }) => {
  const { search } = useLocation();
  const consentFlag = window.localStorage.getItem("consentFlag");
  if (!consentFlag) {
    return <Navigate to={{ pathname: "/", search: search }} replace />;
  }

  return children;
};
