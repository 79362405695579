import styles from "./OfferDescription.module.scss";

import { RewardStatusOffer } from "../../../lib/model/rewardstatus";


const OfferDescription = ({ item }: { item: RewardStatusOffer }) => {
  return (
    <div className={styles.container}>
      <div className={styles.offerImgBox} ><img className={styles.offerImg} src={item.icon ? item.icon : ''}></img></div>
      <div className={styles.offerInfoBox} >
        <div className={styles.offerName}>{item.name}</div>
        <div className={styles.offerDesc}>{item.support_message}</div>
      </div>
    </div>
  );
};

export default OfferDescription;
