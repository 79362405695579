import tinycolor from "tinycolor2";
import styles from "./DynamicCategoryIcon.module.scss";
import LockIcon from "@mui/icons-material/Lock";

//TODO: PNG Resources have a background. We require svg without background...
import { ReactComponent as StaticCategoryAutomobiles } from "../../images/AUTOMOBILES.svg";
import StaticCategoryBeauty from "../../images/campaign.jpeg";
import StaticCategoryBurger from "../../images/expired.png";
import StaticCategoryEducation from "../../images/Education.png";
import StaticCategoryLifestyle from "../../images/Lifestyle.png";
import StaticCategoryTechnology from "../../images/Technology.png";

import animatedCategoryAutomobiles from "../../images/AUTOMOBILES.gif";
import animatedCategoryBeauty from "../../images/Beauty.gif";
import animatedCategoryBurger from "../../images/Burger.gif";
import animatedCategoryEducation from "../../images/Education.gif";
import animatedCategoryLifestyle from "../../images/Lifestyle.gif";
import animatedCategoryTechnology from "../../images/Technology.gif";

//TODO: use category type
const DynamicCategoryIcon = ({
  category,
  locked,
  animate,
  backgroundColor,
  width = 80,
  height = 80,
}: {
  category: string;
  locked: boolean;
  animate: boolean;
  backgroundColor: string;
  width?: number;
  height?: number;
}) => {
  //Only allow switch back to static image, if animation completed
  //TODO: needs improvement (gifs have different durations, timeout is not accurate)
  /*const [internalAnimate, setInternalAnimate] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (timerRef.current === undefined && animate) {
      setInternalAnimate(true);
      timerRef.current = setTimeout(() => {
        setInternalAnimate(false);
        timerRef.current = undefined;
      }, 800);
      return () => {
        if (animate) {
          return;
        }
        clearTimeout(timerRef.current);
        setInternalAnimate(false);
        timerRef.current = undefined;
      };
    }
  }, [animate]);*/

  const parsedColor = tinycolor(backgroundColor);
  const isLight = true;//parsedColor.isLight();

  return locked ? (
    <div
      className={styles.locked}
      style={{
        width: width + "px",
        height: height + "px",
      }}
    >
      <LockIcon />
    </div>
  ) : (
    <div
      className={styles.container}
      style={{
        width: width + "px",
        height: height + "px",
      //  backgroundColor: backgroundColor,
      }}
    >
      {animate ? (
        <img
          style={isLight ? {} : { filter: "invert(1)" }}
          src={mapCategoryToAnimationResource(category)}
          alt="Icon"
        />
      ) : (
        mapCategoryToStaticIcon(category, isLight)
      )}
    </div>
  );
};

const mapCategoryToAnimationResource = (category: string): string => {
  switch (category) {
    case "automobile": {
      return animatedCategoryAutomobiles;
    }
    case "beauty": {
      return animatedCategoryBeauty;
    }
    case "food": {
      return animatedCategoryBurger;
    }
    case "education": {
      return animatedCategoryEducation;
    }
    case "lifestyle": {
      return animatedCategoryLifestyle;
    }
    case "technology": {
      return animatedCategoryTechnology;
    }
    default:
      return animatedCategoryTechnology;
  }
};

const mapCategoryToStaticIcon = (
  category: string,
  isLight: boolean
): React.ReactNode => {
  //TODO: if all resources are available as svg, improve mapping
  const svgFill = isLight ? "#000000" : "#ffffff";
  const imgStyle = isLight ? {} : { filter: "invert(1)" };
  switch (category) {
    case "automobile": {
      return <StaticCategoryAutomobiles fill={svgFill} />;
    }

    case "beauty": {
      return <img style={imgStyle} src={StaticCategoryBeauty} alt="Icon" />;
    }

    case "food": {
      return <img style={imgStyle} src={StaticCategoryBurger} alt="Icon" />;
    }

    case "education": {
      return <img style={imgStyle} src={StaticCategoryEducation} alt="Icon" />;
    }

    case "lifestyle": {
      return <img style={imgStyle} src={StaticCategoryLifestyle} alt="Icon" />;
    }

    case "technology": {
      return <img style={imgStyle} src={StaticCategoryTechnology} alt="Icon" />;
    }

    default:
      return <img style={imgStyle} src={StaticCategoryTechnology} alt="Icon" />;
  }
};

export default DynamicCategoryIcon;
