import { z } from "zod";

export const SurveyAdditionalInfoSchema = z.object({
  quota_id: z.number().nullable(),
});

export const SurveySchema = z.object({
  id: z.string().nullable(),
  partner_name: z.string().nullable(),
  cpi: z.string(),
  loi: z.string(),
  category: z.string(),
  url: z.string(),
  additional_info: z.any(),
  missing_qualifications: z.array(z.string()),
  data: z.string().optional().nullable(),
});

export const SurveyListSchema = z.array(SurveySchema);

export type Survey = z.infer<typeof SurveySchema>;
export type SurveyAdditionalInfo = z.infer<typeof SurveyAdditionalInfoSchema>;
export type SurveyList = z.infer<typeof SurveyListSchema>;
