import styles from "./ProfileCard.module.scss";

import EditIcon from "@mui/icons-material/Edit";

const ProfileCard = ({ onClick }: { onClick: Function }) => {
  return (
    <div className={styles.container} onClick={() => onClick()}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <EditIcon />
        </div>
        <div className={styles.textContent}>
          <div className={styles.header}>Unlock Surveys</div>
          <div className={styles.details}>with your profile activation</div>
        </div>
      </div>
      <button className={styles.startButton}>Start now!</button>
    </div>
  );
};

export default ProfileCard;
