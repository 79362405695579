import { z } from "zod";

export enum QuestionType {
  Single = "select_single",
  Multiple = "select_multiple",
  Number = "number",
  Text = "text",
  Date = "date",
}

export const QuestionOptionSchema = z.object({
  id: z.number(),
  option: z.string(),
});

export const QuestionSchema = z.object({
  id: z.number(),
  sort: z.number(),
  question_type: z.union([
    z.literal(QuestionType.Single),
    z.literal(QuestionType.Multiple),
    z.literal(QuestionType.Number),
    z.literal(QuestionType.Text),
    z.literal(QuestionType.Date),
  ]),
  regex_pattern: z.string(),
  enable_profiler: z.number(),
  enable_raffle: z.number(),
  question: z.string(),
  options: z.array(QuestionOptionSchema).optional(),
});

export const QuestionListSchema = z.array(QuestionSchema);

export type QuestionOption = z.infer<typeof QuestionOptionSchema>;
export type Question = z.infer<typeof QuestionSchema>;
export type QuestionList = z.infer<typeof QuestionListSchema>;
