import styles from "./SurveyCard.module.scss";
import cls from "classnames";

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HelpIcon from "@mui/icons-material/Help";
import DynamicCategoryIcon from "../categoryicon/DynamicCategoryIcon";
import { useState } from "react";

//TODO: move isNew / completesLeft to survey object of api was updated
const SurveyCard = ({
  category,
  isNew,
  completesLeft,
  loi,
  missingQualifications,
  cpi,
  isLocked,
  onClick,
}: {
  category: string;
  isNew: boolean;
  completesLeft: number;
  loi: string;
  missingQualifications: number;
  cpi: string;
  isLocked: boolean;
  onClick: Function;
}) => {
  const [animate, setAnimate] = useState(false);

  return (
    <div
      className={cls({ [styles.locked]: isLocked }, styles.container)}
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => {
        setAnimate(false);
      }}
      onClick={() => onClick()}
    >
      <div className={styles.detailsContent}>
        <DynamicCategoryIcon
          //TODO: use correct category if all are available
          category={"automobile"}
          locked={isLocked}
          animate={animate}
          //TODO: read from config
          backgroundColor={"#3b3c3b"}
        />

        <div className={styles.textContent}>
          <div className={styles.header}>
            <div>{category}</div>
            <div className={styles.statusCard}>
              {isNew && <div className={styles.new}>NEW</div>}
              {completesLeft > 0 && (
                <div className={styles.lowCompletes}>
                  {completesLeft} completes left
                </div>
              )}
            </div>
          </div>

          <div className={styles.details}>
            <div className={styles.item}>
              <AccessTimeFilledIcon fontSize="inherit" />
              <span>{loi} Minutes</span>
            </div>
            {missingQualifications > 0 && (
              <div className={styles.item}>
                <HelpIcon fontSize="inherit" />
                <span>{missingQualifications + " Qualifications"}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <button className={styles.startButton}>{"+ " + cpi}</button>
    </div>
  );
};

export default SurveyCard;
