import { useState } from "react";

import styles from "./RewardStatusSubmitTicket.module.scss";

import stylesUpload from "./Upload.module.scss";

import useRewardStatusParameters from "../../hooks/useRewardStatusParameters";

import { RewardStatusUser, RewardStatusOffer } from "../../lib/model/rewardstatus";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

import Select from "react-select";

import { useLocationWithSurveyStatus } from "../../hooks/useLocationWithSurveyStatus";
import PageContainer from "../../components/page/PageContainer";
import { useStickyHeaderScrollForward } from "../../hooks/useStickyHeaderScrollForward";
import RewardStatusHeader from "../../components/rewardstatusheader/RewardStatusHeader";

import RejectedIcon from '../../images/rejected.png';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const MAX_COUNT_UPLOADS = 5;

export type TOption = {
  label: string
  value: string;
};

const RewardStatusSubmitTicket = () => {

  const [externalIdentifier, adslotId] = useRewardStatusParameters();

  const scrollListener = useStickyHeaderScrollForward();
  const { state } = useLocationWithSurveyStatus();

  const location = useLocation();

  const navigate = useNavigate();

  var tasks:any = [];

  location.state.item.tasks.forEach((task:any) => {
    tasks.push({value: task.name, label: task.name});
  });


  const navigateToDetails = (user : RewardStatusUser, rewardstatusoffer : RewardStatusOffer) => {
    return navigate('/rewardstatussubmitted?external_identifier='+externalIdentifier+'&adSlot='+adslotId,{state:{user: user, item:rewardstatusoffer}});
  }

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [fileLimit, setFileLimit] = useState(false);

  const [count, setCount] = useState(0);

  const [email, setEmail] = useState("");
  const [taskname, setTaskName] = useState(tasks.length > 0 ? tasks[0].value : "");
  const [message, setMessage] = useState("");

  const [emailError, setEmailError] = useState(0);
  const [messageError, setMessageError] = useState(0);
  const [proofError, setProofError] = useState(0);

  const handleUploadFiles = (files:any) => {
    const uploaded : any[] = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file:any) => {
        if (uploaded.findIndex((f:any) => f.name === file.name) === -1) {
          
  
          file.src = URL.createObjectURL(file);
         
            uploaded.push(file);
            if (uploaded.length === MAX_COUNT_UPLOADS) setFileLimit(true);
            if (uploaded.length > MAX_COUNT_UPLOADS) {
                console.log("File Limit reached");
                limitExceeded = true;
                return true;
            }
        }
    })
    if (!limitExceeded) setUploadedFiles(uploaded)

}

const removeImg = (id:any) => {
  var array = [...uploadedFiles]; // make a separate copy of the array
  if (id !== -1) {
    array.splice(id, 1);
    setUploadedFiles(array);
    setFileLimit(false);
  }
}

const handleFileEvent =  (e:any) => {
  const chosenFiles = Array.prototype.slice.call(e.target.files)
  handleUploadFiles(chosenFiles);
}


  const handleEmailChange = (e:any) => {
    setEmail(e.target.value);
  }

  const handleTaskNameChange = (selected?: TOption | null) => {
    let taskNameNew = selected ? selected : {label: "", value: ""};
    console.log(taskNameNew.value);
    setTaskName(taskNameNew.value);
  }

  const handleMessageChange = (e:any) => {
    setMessage(e.target.value);
  }
  
  const selectCustomStyles = {
    control: (baseStyles:any) => ({
      ...baseStyles,
      borderRadius: "8px",
    }),
  };

  const submitTicketData = () => {

    const recipeUrl = `${process.env.REACT_APP_API_ENDPOINT}/UserManagement/ow_submit_ticket?XDEBUG_SESSION_START=PHPSTORM`;

    const formData = new FormData();

    formData.append("placement_id",location.state.user.publisher_placement_id);
    formData.append("user_id",location.state.user.id);
    formData.append("publisher_id",location.state.user.publisher_id);
    formData.append("campaign_id",location.state.item.campaign_id);
    formData.append("email",email);
    formData.append("task_name",taskname);
    formData.append("message",message);

    uploadedFiles.map((file, index) => {
      formData.append("proof[]",file);
    });

    const requestMetadata = {
        mode: 'no-cors' as RequestMode,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: formData
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(recipes => {});
  }

  const checkBeforeSend = () => {

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let returnValue = true;

    if(!re.test(email)){
      setEmailError(1);
      returnValue = false;
    }else{
      setEmailError(0);
    }

    if(count < 50){
      setMessageError(1);
      returnValue = false;
    }else{
      setMessageError(0);
    }

    if(uploadedFiles.length == 0){
      setProofError(1);
      returnValue = false;
    }else{
      setProofError(0);
    }

    return returnValue;
  }


  return (
    <PageContainer scrollBarVisible={false} scrollListener={scrollListener}>

      <RewardStatusHeader dropdownOnChange={()=>{}} dropdown={false} userId={location.state.user.id}></RewardStatusHeader>


      <div className={styles.sectionInputOffer} >
        <label>Affected Campaign</label>
        <div className={styles.campaignName}>{location.state.item.name}</div> 
      </div> 

      { location.state.item.campaign_type == "cpe" ?

      <div className={styles.sectionInputSelectTask} >
      <label>Select Task</label>
      <Select defaultValue={tasks.length > 0 ? tasks[0] : ""} onChange={handleTaskNameChange} options={tasks} styles={selectCustomStyles} components={{
    IndicatorSeparator: () => null
  }} />
      </div>

      : ""

      }

      <div className={styles.sectionInputEmail} >
       <label>Your Email</label>
       <input onChange={handleEmailChange} className={styles.emailInput}></input> 

       {emailError ? <p style={{color: "darkred"}}>E-Mail is not valid</p> : ""}

      </div> 
      <div className={styles.sectionInputMessage} >
        <label>Message</label>  
        <textarea onChange={e => {handleMessageChange(e);setCount(e.target.value.length)}} className={styles.messageInput}></textarea>
        <div className={styles.bottom}>
        <div className={styles.left}>* Minimum 50 characters required ({count}/50)</div>
          <div className={styles.right}><button style={fileLimit ? {'backgroundColor': "grey"} : {}} disabled={fileLimit} onClick={() => {document.getElementById("fileUpload")?.click()}} className={styles.uploadButton}><FileUploadIcon style={{marginTop: "1px", height: "15px",stroke: "white", strokeWidth: "1.5px", color: "white", marginRight: "4px", marginLeft: "-2px"}}></FileUploadIcon><div style={{marginBottom: "2px"}}>Attach Screenshots</div><input type="file" id="fileUpload" style={{display:"none"}} disabled={fileLimit} onChange={handleFileEvent} /></button></div>
        </div>

        {messageError ? <p style={{color: "darkred"}}>Message has to be at least 50 characters long</p> : ""}

        {proofError ? <p style={{color: "darkred"}}>At least one screenshot/proof has to be provided</p> : ""}

      </div> 
      <div id={stylesUpload["newImagesList"]}>
						<ul id={stylesUpload["newImagesList"]}>{uploadedFiles.map((file, index) => (
                    <li className={stylesUpload.previewLoadedImage} onClick={() => {removeImg(index)}} >
                        <img className={stylesUpload.loadedImage} src={file.src}></img><div className={stylesUpload.hoverBox} onClick={() =>{}} ><img style={{textAlign: "center"}} src={RejectedIcon} /></div>
                    </li>
                ))}</ul>
                <div className={styles.limitBox} style={fileLimit ? {} : {'display': "none"}} >
                  You can upload max. 5 screenshots!
                </div>
					</div>
				
      <div className={styles.sectionInputDesc} >
        <div className={styles.desc}>If you downloaded an app and completed an action, please provide screenshot(s) showing that you meet the requirements</div>
        <div className={styles.descAppend}>Please review the offer instructions carefully and submit screenshot(s) showing that you completed the offer.</div>
      </div>
  
      <div className={styles.sectionInputButton} >
        <button className={styles.submitButton} onClick={() => {
          if(checkBeforeSend()){
            submitTicketData();
            navigateToDetails(location.state.user,location.state.item);
          } 
        }
          }>Submit Ticket</button>

      </div>  

    </PageContainer>
  );
};

export default RewardStatusSubmitTicket;
