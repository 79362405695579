export default function useRewardStatusParameters(): [string, string] {
  const urlParams = new URLSearchParams(window.location.search);
  const adslotId = urlParams.get("adSlot");
  const externalIdentifier = urlParams.get("external_identifier");

  if (!adslotId || !externalIdentifier) {
    throw Error("adSlot and externalIdentifier required");
  }

  return [externalIdentifier, adslotId];
}
