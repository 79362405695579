import styles from "./IconMessage.module.scss";
import WarningIcon from "@mui/icons-material/Warning";

const IconMessage = ({ message }: { message: string }) => {
  return (
    <div className={styles.container}>
      <h2>{message}</h2>
      <div>
        <WarningIcon />
      </div>
    </div>
  );
};

export default IconMessage;
