import { Navigate, useLocation } from "react-router";

const LandingPageGuard = ({ children }: { children: JSX.Element }) => {
  const { search } = useLocation();
  const consentFlag = window.localStorage.getItem("consentFlag");
  if (consentFlag) {
    return <Navigate to={{ pathname: "/surveys", search: search }} replace />;
  }

  return children;
};

export default LandingPageGuard;
