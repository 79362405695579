import { useState } from "react";
import styles from "./LandingPage.module.scss";
import PolicyPopup from "./components/popup/PolicyPopup";
import { useLocation, useNavigate } from "react-router-dom";
import useSurveyWallParameters from "../../hooks/useSurveyWallParameters";
import useThrowAsyncError from "../../hooks/useThrowAsyncError";
import { authenticate } from "../../lib/surveyApi";
import PageContainer from "../../components/page/PageContainer";
import SurveyCard from "../../components/surveycard/SurveyCard";
import WarningIcon from "@mui/icons-material/Warning";
import ProfileCard from "../../components/profilecard/ProfileCard";
import SurveyGrid from "../../components/surveygrid/SurveyGrid";
import generateRandomSurveys from "../../lib/surveyGenerator";
import { Survey } from "../../lib/model/survey";

const LandingPage = () => {
  const [showPolicy, setShowPolicy] = useState(true);
  const [showRejectedMessage, setShowRejectedMessage] = useState(false);
  const [showPolicyAcceptLoading, setShowPolicyAcceptLoading] = useState(false);
  const [placeholderSurveys] = useState<Survey[]>(generateRandomSurveys(7));
  const [adSlot, externalIdentifier] = useSurveyWallParameters();
  const { search } = useLocation();
  const navigate = useNavigate();
  const throwAsyncError = useThrowAsyncError();

  const acceptPolicies = async () => {
    try {
      setShowPolicyAcceptLoading(true);
      await authenticate(window, adSlot, externalIdentifier);
      window.localStorage.setItem("consentFlag", "true");
      navigate({ pathname: "/rewardstatusoffers", search: search });
    } catch {
      setShowPolicyAcceptLoading(false);
      throwAsyncError(new Error());
    }
  };

  const rejectPolicies = () => {
    setShowPolicy(false);
    setShowRejectedMessage(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.backgroundContent}>
        <PageContainer scrollBarVisible={!showRejectedMessage}>
          {showRejectedMessage ? (
            <div className={styles.declinedContainer}>
              <div className={styles.declinedContentContainer}>
                <p>
                  You declined to accept the <b>Privacy Policy Terms</b> of
                  ayeT-Studios and therefore can't use the offerwall.
                </p>
                <WarningIcon />
                <p>
                  If you want to use it in the future, please close and reopen
                  the offerwall or refresh the website and accept the Privacy
                  Policy Terms.
                </p>
              </div>
            </div>
          ) : (
            <SurveyGrid>
              <ProfileCard key={0} onClick={() => {}} />
              {placeholderSurveys.map((survey, index) => (
                <SurveyCard
                  key={index + 1}
                  category={survey.category}
                  isNew={true}
                  completesLeft={0}
                  loi={survey.loi}
                  missingQualifications={survey.missing_qualifications.length}
                  cpi={survey.cpi}
                  isLocked={true}
                  onClick={() => {}}
                />
              ))}
            </SurveyGrid>
          )}
        </PageContainer>
      </div>

      <div className={styles.overlay}>
        {showPolicy && (
          <PolicyPopup
            loading={showPolicyAcceptLoading}
            acceptPolicies={acceptPolicies}
            rejectPolicies={rejectPolicies}
          />
        )}
      </div>
    </div>
  );
};

export default LandingPage;
