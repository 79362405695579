import styles from "./RewardStatusSubmitted.module.scss";

import PageContainer from "../../components/page/PageContainer";
import { useStickyHeaderScrollForward } from "../../hooks/useStickyHeaderScrollForward";
import RewardStatusHeader from "../../components/rewardstatusheader/RewardStatusHeader";

import { useNavigate } from "react-router-dom";

import congrIcon from '../../images/Congratulations.png';
import { useLocation } from "react-router";

import Button from "@mui/material/Button";

const RewardStatusSubmitTicket = () => {

  const scrollListener = useStickyHeaderScrollForward();

  const location = useLocation();
  const navigate = useNavigate();

  const navigateBack = () => {
    return navigate(-1);
  }

  return (
    <PageContainer scrollBarVisible={false} scrollListener={scrollListener}>

      <RewardStatusHeader dropdownOnChange={()=>{}} dropdown={false} userId={location.state.user.id}></RewardStatusHeader>

      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}><img height={110} src={congrIcon} ></img><div className={styles.noOffersFirstLine}>Congratulations!</div><div className={styles.secondLine}>You successfully submited a ticket.</div></div>

      <div className={styles.buttonBackBox}>
        <Button onClick={()=>{navigateBack()}}>Go Back</Button>
      </div> 

    </PageContainer>
  );
};

export default RewardStatusSubmitTicket;
