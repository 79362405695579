import styles from "./PageContainer.module.scss";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import classnames from "classnames";
import AyetLogoSubtle from "../ayetLogoSubtle/AyetLogoSubtle";

export interface PageContainerProps {
  // Children
  children: React.ReactNode;

  // show / hide scrollbar
  scrollBarVisible?: boolean;

  // scroll position update listener
  scrollListener?: (scrollHeight: number, position: number) => void;

  // main className
  className?: string;

  // content className
  classNameContent?: string;

  // scroll className
  classNameScroll?: string;
}

export type PageContainerHandle = {
  resetScroll: () => void;
};

/**
 * PageContainer
 *
 * Wrapping container of react router pages.
 * Handles: scrolling, unified design, branding
 */
const PageContainer = forwardRef<PageContainerHandle, PageContainerProps>(
  (props, ref) => {
    //Content reference
    const contentRef = useRef<HTMLDivElement>(null);

    //Forward current scroll position to listener
    const handleScroll = () => {
      if (!contentRef.current || !props.scrollListener) {
        return;
      }
      props.scrollListener(
        contentRef.current.scrollHeight,
        contentRef.current.scrollTop
      );
    };

    //Imperative interaction.
    useImperativeHandle(ref, () => ({
      //Reset scroll position
      resetScroll() {
        if (contentRef.current) {
          contentRef.current.scrollTop = 0;
          contentRef.current.scrollLeft = 0;
        }
      },
    }));

    //Register / Unregister listeners
    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (contentRef.current) {
          contentRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);

    return (
      <div className={classnames(styles.main, props.className)}>
        <div
          className={classnames(
            styles.content,
            {
              [styles.hiddenScroll]: !props.scrollBarVisible ?? true,
            },
            props.classNameContent
          )}
          ref={contentRef}
        >
          <div className={styles.mainContent}>{props.children}</div>
          <div className={styles.footer}>
            <AyetLogoSubtle />
          </div>
        </div>
        {(props.scrollBarVisible ?? true) && (
          <div
            className={classnames(styles.scrollSpacer, props.classNameScroll)}
          />
        )}
      </div>
    );
  }
);

export default PageContainer;
