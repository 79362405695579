import { bool } from "prop-types";
import styles from "./RewardStatusHeader.module.scss";

import Select from "react-select";;


const RewardStatusHeader = ({userId, dropdown, dropdownOnChange} : {userId: number, dropdown: boolean, dropdownOnChange(newValue:any): void}) => {
 

  const options = [
    { value: "all", label: "All" },
    { value: "pending", label: "In Progress" },
    { value: "rejected", label: "Expired" },
    { value: "rewarded", label: "Rewarded" },
  ];

  const onChange = (newValue:any) => {
    dropdownOnChange(newValue);
  };

  const selectCustomStyles = {
    control: (baseStyles:any) => ({
      ...baseStyles,
      borderRadius: "8px",
      width: "110px",
      height: "24px",
      minHeight: "0px",
      fontSize: "10px",
      display: "flex",
      lineHeight: "1.2"
    }),
    valueContainer: (provided:any) => ({
      ...provided,
      display: "flex",
      paddingLeft: "4px",
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "0px"
    }),
    indicatorsContainer: (provided:any) => ({
      ...provided,
      height: '20px',
    }),

  };

  return (
    <div className={styles.container} >
      <div className={styles.userId} >User ID: <span>{userId}</span></div>
      {dropdown == true ? <div className={styles.dropdown}>
        <label>Status</label>
        <Select onChange={(newValue, label) => {onChange(newValue)}} placeholder={"See All"} styles={selectCustomStyles} options={options} components={{
    IndicatorSeparator: () => null
  }} ></Select></div> : ''}
    </div>
  );
};

export default RewardStatusHeader;
