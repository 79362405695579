import styles from "./OfferTasksDetails.module.scss";

import checkedIcon from "../../../images/checked.png";
import uncheckedIcon from "../../../images/unchecked.png";
import { RewardStatusOffer, TaskSchema } from "../../../lib/model/rewardstatus";


const OfferTasksDetails = ({ item }: { item: RewardStatusOffer}) => {
  return (
    <div style={{marginTop: "40px"}}>
      <ul className={styles.listBox}>
        {
         item.tasks.map((task) => <li className={styles.task}>
         <div className={styles.taskDesc}>{task.name}</div>
         <div className={styles.taskCost}>{task.currency_value} €</div>
         <div className={styles.taskImg}><img src={task.converted ? checkedIcon : uncheckedIcon}></img></div>
       </li>)
        
        }
       
      </ul>

    </div>
  );
};

export default OfferTasksDetails;
