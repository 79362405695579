import { useEffect } from "react";
import { useOutletContext } from "react-router";

export type OutletContextType = { scrollListener: (position: number) => void };

export const useStickyHeaderScrollForward = (): ((
  position: number
) => void) => {
  const { scrollListener } = useOutletContext<OutletContextType>();

  useEffect(() => {
    scrollListener(0);
  }, []);

  return scrollListener;
};
