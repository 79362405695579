import { Location } from "react-router";
import { useLocation } from "react-router";

export declare type SurveyStatus = "notAvailable" | "disqualified";

export interface LocationWithSurveyStatus extends Location {
  state: { status?: SurveyStatus };
}

export const useLocationWithSurveyStatus = (): LocationWithSurveyStatus =>
  useLocation() as LocationWithSurveyStatus;
