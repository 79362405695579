import styles from "./OfferStatus.module.scss";

import pendingIcon from "../../../images/PendingPink.png";
import startedIcon from "../../../images/Started.png";
import { RewardStatusOffer } from "../../../lib/model/rewardstatus";

const OfferDetails = ({ item }: { item: RewardStatusOffer}) => {
  return (
    <div style={{marginTop: "20px"}}>
      <div className={styles.title}>Status</div>
      <ul className={styles.listBox}>
        <li className={styles.state}>
          <div className={styles.stateName}>Started</div>
          <div className={styles.stateDatetime}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(item.click_time)}</div>
          <div className={styles.stateImg}><img src={startedIcon}></img></div>
        </li>
        <li className={styles.state}>
          <div className={styles.stateName}>In Progress</div>
          <div className={styles.stateDatetime}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(item.installation_time)}</div>
          <div className={styles.stateImg}><img src={pendingIcon}></img></div>
        </li>
      </ul>

    </div>
  );
};

export default OfferDetails;
