import axios from "axios";
import { CompletionRequiredSchema } from "./model/completionRequired";
import { Question, QuestionListSchema } from "./model/question";
import { RewardStatusOfferSchema, RewardStatusOfferListSchema, RewardStatusResponse, RewardStatusResponseSchema, RewardStatusOfferList } from "./model/rewardstatus";
import { isJwtValid } from "./tokenUtils";

const adSlotStorageKey: string = "adSlot";
const externalIdentifierStorageKey: string = "externalIdentifier";
const userTokenLocalStorageKey: string = "token";
const deviceTokenLocalStorageKey: string = "deviceToken";

/**
 * Authenticate api requests
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 * @returns user_token
 */
export const authenticate = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string
): Promise<string> => {
  //Try to retrieve tokens from local storage
  let savedUserToken = window.localStorage.getItem(userTokenLocalStorageKey);
  let savedDeviceToken = window.localStorage.getItem(
    deviceTokenLocalStorageKey
  );

  //Clear tokens if adSlot / externalIdentifier changed
  const savedAdSlot = window.localStorage.getItem(adSlotStorageKey);
  const savedExternalIdentifier = window.localStorage.getItem(
    externalIdentifierStorageKey
  );
  if (
    savedAdSlot !== adSlot ||
    savedExternalIdentifier !== externalIdentifier
  ) {
    savedUserToken = null;
    savedDeviceToken = null;
  }

  //UserToken is saved
  if (savedUserToken) {
    //Check if token is expired
    if (isJwtValid(savedUserToken)) {
      //Token is not expired, so we can still use it
      return savedUserToken;
    }
  }

  //Authenticate device again if there is no device token or device token is expired
  if (!savedDeviceToken || !isJwtValid(savedDeviceToken)) {
    const identifyBody: Record<string, any> = {
      useragent: window.navigator.userAgent,
    };
    if (savedDeviceToken) {
      identifyBody.device_token = savedDeviceToken;
    }
    const identifyResponse = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/devices/identify?XDEBUG_SESSION_START=PHPSTORM`,
      identifyBody
    );
    savedDeviceToken = identifyResponse.data.deviceToken;
  }

  //TODO: Handle adSlot config
  const adSlotResponse = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/crud/adslots/${adSlot}`
  );

  const userIdentifyResponse = await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/users/identify/${adSlot}?external_identifier=${externalIdentifier}`,
    {
      device_token: savedDeviceToken!,
    }
  );

  if (userIdentifyResponse.data.db_write_required) {
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/rest/v1/devices/update`, {
      external_identifier: externalIdentifier,
      device_token: savedDeviceToken!,
      useragent: window.navigator.userAgent,
      force_db_update: true,
    });
  }

  //Save tokens after successful authentication
  window.localStorage.setItem(deviceTokenLocalStorageKey, savedDeviceToken!);
  window.localStorage.setItem(
    userTokenLocalStorageKey,
    userIdentifyResponse.data.user_token
  );
  window.localStorage.setItem(adSlotStorageKey, adSlot);
  window.localStorage.setItem(externalIdentifierStorageKey, externalIdentifier);

  return userIdentifyResponse.data.user_token;
};

/**
 * Authenticate api requests
 *
 * @param window Window instance
 * @param userAgent externalIdentifier
 * @returns device_token
 */
export const authenticateRewardStatus = async (
  window: Window,
  adSlot: string,
  externalIdentifier: string,
): Promise<string> => {
  //Try to retrieve tokens from local storage
  let savedUserToken = window.localStorage.getItem(userTokenLocalStorageKey);
  let savedDeviceToken = window.localStorage.getItem(
    deviceTokenLocalStorageKey
  );

  
  const savedAdSlot = window.localStorage.getItem(adSlotStorageKey);
  const savedExternalIdentifier = window.localStorage.getItem(
    externalIdentifierStorageKey
  );


  if (
    savedAdSlot !== adSlot ||
    savedExternalIdentifier !== externalIdentifier
  ) {
    savedUserToken = null;
    savedDeviceToken = null;
  }

  //UserToken is saved
  if (savedUserToken) { 
    //Check if token is expired
    if (isJwtValid(savedUserToken)) {
      //Token is not expired, so we can still use it
      return savedUserToken;
    }
  }

  //Authenticate device again if there is no device token or device token is expired
  if (!savedDeviceToken || !isJwtValid(savedDeviceToken)) {
    const identifyBody: Record<string, any> = {
      useragent: window.navigator.userAgent,
    };
    if (savedDeviceToken) {
      identifyBody.device_token = savedDeviceToken;
    }
    const identifyResponse = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/devices/identify?XDEBUG_SESSION_START=PHPSTORM`,
      identifyBody
    );
    savedDeviceToken = identifyResponse.data.deviceToken;
  }

  const userIdentifyResponse = await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/users/identify/${adSlot}?external_identifier=${externalIdentifier}`,
    {
      device_token: savedDeviceToken!,
    }
  );

  if (userIdentifyResponse.data.db_write_required) {
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/rest/v1/devices/update`, {
      external_identifier: externalIdentifier,
      device_token: savedDeviceToken!,
      useragent: window.navigator.userAgent,
      force_db_update: true,
    });
  }

  //Save tokens after successful authentication
  window.localStorage.setItem(deviceTokenLocalStorageKey, savedDeviceToken!);
  window.localStorage.setItem(
    userTokenLocalStorageKey,
    userIdentifyResponse.data.user_token
  );

  window.localStorage.setItem(externalIdentifierStorageKey, externalIdentifier);

  return userIdentifyResponse.data.user_token;
};




/**
 * Read all available surveys
 *
 * @param window Window instance
 * @param adSlot Current adSlot
 * @param externalIdentifier Current externalIdentifier
 * @returns
 */
export const getRewardStatusOffers = async (
  window: Window,
  adslotId: string,
  externalIdentifier: string
): Promise<RewardStatusResponse> => {
  const token = await authenticateRewardStatus(window, adslotId, externalIdentifier);
  const response = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/userSupport/get_reward_status?XDEBUG_SESSION_START=PHPSTORM`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const responseRewardStatus = RewardStatusResponseSchema.parse(response.data);
  return responseRewardStatus;
};



