import { useState } from "react";

/**
 * Apparently this is a common solution for async errors
 * https://www.developerway.com/posts/how-to-handle-errors-in-react
 * @returns
 */
const useThrowAsyncError = () => {
  const [state, setState] = useState();

  return (error: Error) => {
    setState(() => {
      throw error;
    });
  };
};

export default useThrowAsyncError;
