import styles from "./RewardStatusOfferCard.module.scss";
import cls from "classnames";

import { RewardStatusResponse, RewardStatusOfferList, RewardStatusOffer, RewardStatusResponseSchema } from "../../lib/model/rewardstatus";

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HelpIcon from "@mui/icons-material/Help";
import DynamicCategoryIcon from "../categoryicon/DynamicCategoryIcon";
import { useState } from "react";

import appleIcon from "./../../images/Apple.png";
import androidIcon from "./../../images/Android.png";
import desktopIcon from "./../../images/Desktop.png";

import expiredIcon from "../../images/expired.png";
import rewardedIcon from "../../images/Rewarded.png";
import inProgessIcon from "../../images/InProgress.png";

import daysLeftIcon from "../../images/DaysLeft.png";
import coinsIcon from "../../images/Coins.png";

import offerIcon from "../../images/campaign.jpeg";

//TODO: move isNew / completesLeft to survey object of api was updated
const RewardStatusOfferCard = ({
  item,
  status,
  onClick,
}: {
  item: RewardStatusOffer,
  status: string,
  onClick: Function
}) => {


  


  return (
    <div
      className={styles.container}
      onClick={() => onClick()}
    >
      <div className={styles.detailsContent}>

        <img className={styles.offerIcon} height="80" width="80" src={item.icon ? item.icon : "" }
        ></img>

        <div className={styles.textContent}>
          <div className={styles.header}>
            <div >{item.name}</div>
           
          </div>

          <div className={styles.details}>
            <div className={styles.propertyRow} >

              { item.platform == "apple" ? <img className={styles.propertyItemImg} src={appleIcon} /> : item.platform == "android" ? <img className={styles.propertyItemImg} src={androidIcon} /> : <img className={styles.propertyItemImg} src={desktopIcon} />}
              { item.campaign_type == "cpe" ? <div className={styles.propertyItemStat}><span className={styles.current}>{item.tasks.length}</span><span className={styles.max}>/{item.tasks.length}</span> Tasks</div> : "" }
              <div className={styles.propertyItemStat}><img src={daysLeftIcon}></img><span className={styles.current}>{item.days_left}</span> Days Left</div>
              <div className={styles.propertyItemStat}><img src={coinsIcon}></img><span className={styles.current}>{item.rewarded_amount}</span><span className={styles.max}>/{item.total_reward_amount}</span> {item.currency_plural}</div>
            </div>
 
            <div className={styles.desc}>
                {item.support_message}
            </div>
          </div>
        </div>

        <div className={styles.statusContent}>
              <span className={styles.statusText} >{status == "rewarded" ? "Rewarded" : status == "pending" ? "In Progress" : "Expired"}</span>
              <span className={styles.dateTime} >{item.installation_time != 0 ? 'Installed' : 'Not Installed'}</span>  
        </div>
        <div className={styles.statusIconContent}>
          <img height="34" src={status == "rejected" ? expiredIcon : status == "pending" ? inProgessIcon : rewardedIcon}></img>
        </div>


      </div>
      
    </div>
  );
};

export default RewardStatusOfferCard;