import jwt_decode from "jwt-decode";

//We treat the token as expired earlier to account for possible clock skew
const clockSkewOffset = 600;

export const isJwtValid = (rawToken: string): boolean => {
  const decoded: any = jwt_decode(rawToken);

  //Malformed token
  if (!decoded || !decoded.exp) {
    return false;
  }

  //Check for expiry
  const expire: number = parseInt(decoded.exp) - clockSkewOffset;
  const now: number = Math.floor(Date.now() / 1000);

  return expire >= now;
};
