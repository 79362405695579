import { createTheme } from "@mui/material";

const generateTheme = (
  accentColor: string,
  neutralColor: string,
  headerColor: string,
  borderRadius: string
) => {
  return createTheme({
    borderRadius: borderRadius,
    palette: {
      primary: {
        main: accentColor,
      },
      neutral: neutralColor,
      header: headerColor,
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            padding: "4px 26px",
            minHeight: "32px",
            fontWeight: 600,
            fontSize: "16px",
            fontStyle: "normal",
            fontFamily: "Open Sans",
            borderRadius: borderRadius,
            lineHeight: "normal",
            textTransform: "none",
          },
          outlined: {
            borderColor: "black",
            color: "black",

            "&:hover": {
              borderColor: "gray",
            },
          },
        },
      },
    },
  } as any);
};

export default generateTheme;
