import React, { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import styles from "./Header.module.scss";
import { IconButton } from "@mui/material";
import Drawer from "../drawer/Drawer";
import { motion } from "framer-motion";
import AyetLogoSubtle from "../ayetLogoSubtle/AyetLogoSubtle";
import tinycolor from "tinycolor2";
import { useTheme } from "@mui/material";

export interface HeaderProps {
  title: string;
  collapsed: boolean;
  showContextIcon: boolean;
  showBackIcon?: boolean;
  drawerTitle?: string;
  image?: string;
  drawerBuilder?: (closeCallback: () => void) => React.ReactNode;
  onBackClick?: () => void;
}

const Header = ({
  title,
  collapsed,
  showContextIcon,
  showBackIcon = false,
  drawerTitle = "",
  image,
  drawerBuilder = () => <></>,
  onBackClick = () => {},
}: HeaderProps) => {
  const theme = useTheme();

  const [showDrawer, setShowDrawer] = useState(false);
  const collapseDuration = 0.25;
  const imageFadeDuration = 0.25;
  const textFadeDuration = 0.25;
  const headerHeight = 100;
  const headerHeightCollapsed = 60;
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  //Calculate background luminescence
  const headerBackground = theme.palette.header;
  const headerUseBoxed = tinycolor(headerBackground).getLuminance() >= 0.9;
  const foregroundColor = tinycolor(headerBackground).isLight()
    ? "#231f20"
    : "white";
  const dynamicColorStyle = { color: foregroundColor };
  const dynamicHeaderStyle = {
    backgroundColor: headerBackground,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const dynamicHeaderImageStyle = {
    gridArea: "overlay",
    width: "100%",
    height: "100%",
    backgroundColor: headerBackground,
    backgroundImage: `url("${image}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <motion.div
        className={
          headerUseBoxed ? styles.headerContainerBoxed : styles.headerContainer
        }
        style={dynamicHeaderStyle}
        transition={{ duration: collapseDuration }}
        initial={{ height: collapsed ? headerHeightCollapsed : headerHeight }}
        animate={{
          height: collapsed ? headerHeightCollapsed : headerHeight,
        }}
      >
        {image && (
          <motion.div
            style={dynamicHeaderImageStyle}
            transition={{ duration: imageFadeDuration }}
            initial={{ opacity: collapsed ? 0 : 1 }}
            animate={{
              opacity: collapsed ? 0 : 1,
            }}
          />
        )}

        <div className={styles.headerContent}>
          {/** Optional navigation button */}
          {showBackIcon ? (
            <IconButton aria-label="back" onClick={onBackClick}>
              <ArrowBackIosNewIcon style={dynamicColorStyle} />
            </IconButton>
          ) : (
            <div className={styles.placeholder} />
          )}

          {/** Header title */}
          <motion.div
            transition={{ duration: textFadeDuration }}
            initial={{ opacity: image && !collapsed ? 0 : 1 }}
            animate={{
              opacity: image && !collapsed ? 0 : 1,
            }}
          >
            <h1 style={dynamicColorStyle}>{title}</h1>
          </motion.div>

          {/** Optional context button */}
          {showContextIcon ? (
            <IconButton aria-label="menu" onClick={toggleDrawer}>
              <MoreVertIcon style={dynamicColorStyle} />
            </IconButton>
          ) : (
            <div className={styles.placeholder} />
          )}
        </div>
      </motion.div>

      <Drawer open={showDrawer} onOpenChange={toggleDrawer}>
        <div
          className={
            headerUseBoxed
              ? styles.headerContainerBoxedDrawer
              : styles.headerContainer
          }
          style={{
            ...dynamicHeaderStyle,
            height: collapsed ? headerHeightCollapsed : headerHeight,
          }}
        >
          {image && !collapsed && <div style={dynamicHeaderImageStyle} />}
          <div className={styles.headerContent}>
            <div className={styles.placeholder} />
            <h1 style={dynamicColorStyle}>
              {image && !collapsed ? "" : drawerTitle}
            </h1>
            <IconButton aria-label="close" onClick={toggleDrawer}>
              <ArrowForwardIcon style={dynamicColorStyle} />
            </IconButton>
          </div>
        </div>
        <div className={styles.drawerBody}>
          {drawerBuilder(() => toggleDrawer())}
          <div className={styles.drawerLogo}>
            <AyetLogoSubtle />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
