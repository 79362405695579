import React from "react";
import styles from "./ContentFrame.module.scss";

export interface ContentFrameProps {
  children: React.ReactNode;
}

const ContentFrame = ({ children }: ContentFrameProps) => {
  return (
    <div className={styles.main}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ContentFrame;
