import ReactDOM from "react-dom";
import styles from "./Drawer.module.scss";
import React, { useCallback, useEffect, MouseEvent } from "react";
import { motion, AnimatePresence } from "framer-motion";

export interface DrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onExitAnimationComplete?: () => void;
  children: React.ReactNode;
}

const Drawer = ({
  open,
  onOpenChange,
  onExitAnimationComplete = () => {},
  children,
}: DrawerProps) => {
  const [isMouseInsideOverlay, setMouseInsideOverlay] =
    React.useState<boolean>(false);

  //Escape close event handling
  const closeOnEscapeKeyDown = useCallback(
    (ev: KeyboardEvent): any => {
      if (ev.key === "Escape") {
        onOpenChange(false);
      }
    },
    [onOpenChange]
  );

  //Scroll event handling
  const onScroll = useCallback(
    (ev: Event): any => {
      if (!isMouseInsideOverlay) {
        onOpenChange(false);
      }
    },
    [onOpenChange, isMouseInsideOverlay]
  );

  //Register / Unregister key events
  useEffect(() => {
    if (open) {
      document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    } else {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    }
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown, open]);

  //Register scroll events
  useEffect(() => {
    if (open) {
      window.addEventListener("scroll", onScroll);
    } else {
      window.removeEventListener("scroll", onScroll);
    }
    return function cleanup() {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll, open]);

  //Background scroll
  useEffect(() => {
    if (isMouseInsideOverlay && open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMouseInsideOverlay, open]);

  return ReactDOM.createPortal(
    <AnimatePresence
      initial={true}
      mode={"wait"}
      onExitComplete={onExitAnimationComplete}
    >
      {open && (
        <div
          className={styles.frame}
          onClick={() => {
            onOpenChange(false);
          }}
        >
          <motion.div
            className={styles.overlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.1,
            }}
          >
            <div className={styles.overlayContainer}>
              <motion.div
                className={styles.overlayContent}
                onClick={(e: MouseEvent) => e.stopPropagation()}
                initial={{
                  y: 0,
                  x: "100%",
                }}
                animate={{
                  y: 0,
                  x: 0,
                  transition: {
                    duration: 0.2,
                  },
                }}
                exit={{
                  y: 0,
                  x: "100%",
                }}
                onMouseEnter={() => setMouseInsideOverlay(true)}
                onMouseLeave={() => setMouseInsideOverlay(false)}
              >
                {children}
              </motion.div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Drawer;
