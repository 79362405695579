import React, { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import styles from "./Header.module.scss";
import { IconButton } from "@mui/material";
import Drawer from "../drawer/Drawer";
import AyetLogoSubtle from "../ayetLogoSubtle/AyetLogoSubtle";
import tinycolor from "tinycolor2";
import { useTheme } from "@mui/material";
import FloatingBanner from "../floatingBanner/FloatingBanner";

export interface DynamicHeightHeaderProps {
  title: string;
  height: number;
  heightRange: [number, number];
  showContextIcon: boolean;
  showBackIcon?: boolean;
  drawerTitle?: string;
  image?: string;
  floatingBanner?: React.ReactNode;
  drawerBuilder?: (closeCallback: () => void) => React.ReactNode;
  onBackClick?: () => void;
}

const DynamicHeightHeader = ({
  title,
  height,
  heightRange,
  showContextIcon,
  showBackIcon = false,
  drawerTitle = "",
  image,
  floatingBanner,
  drawerBuilder = () => <></>,
  onBackClick = () => {},
}: DynamicHeightHeaderProps) => {
  const theme = useTheme();

  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  //Helper
  const normalizedHeight = Math.min(
    Math.max(height, heightRange[0]),
    heightRange[1]
  );
  const collapsed = normalizedHeight === heightRange[0];
  const opacityFromHeight = (
    height: number,
    heightRange: [number, number]
  ): number => {
    return (
      ((height - heightRange[0]) * 100) /
      (heightRange[1] - heightRange[0]) /
      100
    );
  };

  //Calculate background luminescence
  const headerBackground = theme.palette.header;
  const headerUseBoxed = tinycolor(headerBackground).getLuminance() >= 0.9;
  const foregroundColor = tinycolor(headerBackground).isLight()
    ? "#231f20"
    : "white";
  const dynamicColorStyle = { color: foregroundColor };
  const dynamicHeaderStyle = {
    height: normalizedHeight,
    backgroundColor: headerBackground,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const dynamicHeaderImageStyle = {
    gridArea: "overlay",
    width: "100%",
    height: "100%",
    backgroundColor: headerBackground,
    backgroundImage: `url("${image}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: opacityFromHeight(normalizedHeight, heightRange),
  };

  const dynamicHeaderTitleStyle = {
    ...dynamicColorStyle,
    //Always visible if there is no background image
    //With image only visible in collapsed state
    opacity: image ? 1.0 - opacityFromHeight(normalizedHeight, heightRange) : 1,
  };

  return (
    <>
      <div
        className={
          headerUseBoxed ? styles.headerContainerBoxed : styles.headerContainer
        }
        style={dynamicHeaderStyle}
      >
        {image && <div style={dynamicHeaderImageStyle} />}

        <div className={styles.headerContent}>
          {/** Optional navigation button */}
          {showBackIcon ? (
            <IconButton aria-label="back" onClick={onBackClick}>
              <ArrowBackIosNewIcon style={dynamicColorStyle} />
            </IconButton>
          ) : (
            <div className={styles.placeholder} />
          )}

          {/** Header */}
          <h1 style={dynamicHeaderTitleStyle}>{title}</h1>

          {/** Optional context button */}
          {showContextIcon ? (
            <IconButton aria-label="menu" onClick={toggleDrawer}>
              <MoreVertIcon style={dynamicColorStyle} />
            </IconButton>
          ) : (
            <div className={styles.placeholder} />
          )}
        </div>
      </div>

      <Drawer open={showDrawer} onOpenChange={toggleDrawer}>
        <div
          className={
            headerUseBoxed
              ? styles.headerContainerBoxedDrawer
              : styles.headerContainer
          }
          style={{
            ...dynamicHeaderStyle,
            height: normalizedHeight,
          }}
        >
          {image && !collapsed && <div style={dynamicHeaderImageStyle} />}
          <div className={styles.headerContent}>
            <div className={styles.placeholder} />
            <h1 style={dynamicColorStyle}>
              {image && !collapsed ? "" : drawerTitle}
            </h1>
            <IconButton aria-label="close" onClick={toggleDrawer}>
              <ArrowForwardIcon style={dynamicColorStyle} />
            </IconButton>
          </div>
        </div>
        <div className={styles.drawerBody}>
          {drawerBuilder(() => toggleDrawer())}
          <div className={styles.drawerLogo}>
            <AyetLogoSubtle />
          </div>
        </div>
      </Drawer>

      {floatingBanner && (
        <FloatingBanner
          open={collapsed}
          offsetTop={normalizedHeight}
          onOpenChange={() => {}}
          onExitAnimationComplete={() => {}}
        >
          {floatingBanner}
        </FloatingBanner>
      )}
    </>
  );
};

export default DynamicHeightHeader;
