import React from "react";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <h4>Privacy Policy Agreement</h4>

      <h5>Privacy Policy of the Services of the ayeT-Studios GmbH</h5>

      <p>
        The ayeT-Studios GmbH d/b/a “ayeT-Studios” and any future corporate
        affiliates (collectively, “ayeT-Studios”) put great efforts in making
        sure that personal information is safe and used only in full compliance
        with applicable data protection laws and regulations.
      </p>
      <p>
        This privacy policy (“Policy”) explains ayeT-Studios’ privacy practices
        for processing personal information via ayeT-Studios’ Services (the
        “Services”). It applies whenever you access or use a mobile application
        or website that is connected and/or uses the Services (as applicable).
        Please take the time to read the full Policy.
      </p>

      <h5>The ayeT-Studios Services Privacy Policy</h5>

      <p>
        ayeT-Studios develops and operates several services in the ad-tech
        environment, such as a mediation platform, programmatic mediation
        platform, incentivized offer walls, direct sales service and other
        online advertising-related services, all of which may include
        advertising management services, such as optimization, ad serving,
        campaign management and the compilation of relevant statistical data
        (collectively, the “Services”).
      </p>

      <p>
        This Policy explains ayeT-Studios’ privacy practices for processing
        personal information via the Services and it applies whenever you access
        or use the Property of a Supply Partner that is connected to and/or uses
        the Services. Our Services are not directed to children under 16 years
        of age and we do not intentionally or knowingly collect personal
        information on such users.
      </p>

      <h5>1. The Services</h5>
      <p>
        ayeT-Studios provides advertisers, agencies, DSPs and/or ad networks
        (collectively, “Demand Partners”) and website and/or mobile
        application/website developers and/or owners and/or publishers
        (collectively, “Supply Partners”; Supply Partner and Demand Partners are
        also referred to hereinafter as “Customers”) with the following
        Services:
        <ul>
          - Mediation service, that enables Supply Partners to optimize
          placement of ads by third-party Demand Partners on their website or
          mobile application/website (“Property”) and for Demand Partners to buy
          ad inventory and serve ads directly on such Property;
        </ul>
        <ul>
          - Programmatic Mediation service, that enables Supply Partners and
          Demand Partners to use programmatic auction for the sale and purchase
          of advertising space inventory on the Property;
        </ul>
        <ul>
          - Offer Wall service, that enables Supply Partners and Demand Partners
          to use an opt-in scrollable offers which includes individual Ads to
          integrate into Property.
        </ul>
        <ul>
          - Direct Sales service, that enables Supply Partners and Demand
          Partners to run ad campaigns on certain Property.
        </ul>
        <ul>
          - From time to time, ayeT-Studios may offer ancillary services to the
          services described above and additional services.
        </ul>
        <ul>
          - ayeT-Studios has no direct interaction with the individuals that
          visit Customers’ services (“Users”), except for the ayeT-Studios Offer
          Wall service where ayeT-Studios provides technical support only in
          behalf of Supply Partner to Users of the Offer Wall.
        </ul>
        <ul>
          - ayeT-Studios’ Customers are required to provide an adequate notice
          to Users about this Privacy Policy and to provide a privacy policy of
          their own, as required by the applicable legislation.
        </ul>
      </p>

      <h5>
        2. The Personal Information that ayeT-Studios collects and receives
      </h5>

      <p>
        ayeT-Studios collects personal information in order to provide the
        Services and for other specific purposes, as described in this Privacy
        Policy. Please note that you are not required by law to provide
        ayeT-Studios with any personal information.
      </p>

      <p>
        ayeT-Studios only collects information that the Supply Partner had
        instructed ayeT-Studios to collect, in writing, prior to such
        collection, and subject to your permissions inside the Property and/or
        your mobile device.
      </p>

      <p>
        As an example, ayeT-Studios’ Software Development Kit (“SDK”) that may
        be installed on the Property you are using will not collect your
        location data unless you affirmatively allowed/enabled the Property to
        collect it. Other information may be received via an API integration
        between ayeT-Studios’ server and trusted third-party’s servers and
        additional information may be received by embedding tags on websites you
        visit.
      </p>

      <p>
        From time to time, ayeT-Studios may use additional or alternative tools
        to collect information, in which case this Privacy Policy will be
        amended accordingly.
      </p>

      <p>
        When interacting with the Property, ayeT-Studios may collect/receive the
        following information:
      </p>
      <p>
        <ul>
          - Information about your device, such as device type and model,
          network provider, browser type, language.
        </ul>
        <ul>
          - Information about the Property, such as package name, keywords,
          version.
        </ul>
        <ul>
          - Information about your operating system, such as Android, iOS.
        </ul>
        <ul>
          - Information about your mobile advertising identifiers, such as your
          Advertising ID (Apple: IDFA or Google: GAID).
        </ul>
        <ul>
          - Information provided by your device, such as device IP address,
          network connection type and device GPS location (only if you provide
          permission).
        </ul>
        <ul>
          - Information ayeT-Studios may receive from Supply Partners, such as
          your age, gender, zip code, GPS location.
        </ul>
        <ul>
          - Information ayeT-Studios may receive from Third Parties about you
          from sources other than the Property, such as non-precise device
          location based on IP address, device specifications and User’s
          interests.
        </ul>
        <ul>
          - Demographic and user interest data that you choose to share with us
          by taking part in surveys or similar. Those data collected through the
          survey process can be associated with aforementioned data / cookie
          collected data.
        </ul>
      </p>

      <p>
        When interacting with the Property and you choose to participate in
        surveys or similar, ayeT-Studios may collect/receive and share the
        following information:
      </p>
      <p>
        <ul>
          - ayeT-Studios may share your personal information and/or
          social-demographic information, including, without limitation, a
          unique identification number (“UID”), postal code, region, province,
          gender, marital status, education, ethnicity/race (where permitted by
          applicable law), employment related information, non-personally
          identifiable information on household members, consumer product and
          service usage and ownership (e.g., computers, cars, mobile devices,
          video games, etc.), home ownership, pet ownership, employment
          information (e.g., title, role, travel, industry, etc.), and social
          grade, with third party sample/market research companies (individually
          each a “Third Party MR Company” and collectively the “Third Party MR
          Companies”) for the following purposes:
        </ul>
        <ul>
          a. Identifying client survey opportunities that you may be eligible
          for through the Third Party MR Companies; and
        </ul>
        <ul>
          b. Disclosing such data elements to clients of the Third Party MR
          Companies for audience measurement services; and
        </ul>
        <ul>
          c. Appending such data elements to completed client surveys and
          delivering the appended survey data to clients of the Third Party MR
          Company(ies) for analytical or research purposes; and
        </ul>
        <ul>d. Other uses as set forth in their privacy policy.</ul>
        <ul>
          - If you are eligible for a client survey opportunity offered through
          a Third Party MR Company, the Third Party MR Company will provide your
          unique identification number and a survey link to us and we will
          invite you to participate in the client survey through our
          incentivized offer walls. We will not share your name, email address,
          or phone number with the Third Party MR Companies. In connection with
          the sharing of your information with a Third Party MR Company, your
          information may be transferred and stored outside of your country of
          residence, including, without limitation, in the United States, and by
          agreeing to this privacy policy you are agreeing to the processing and
          exportation of your personal information as set forth in this Section.
          If you complete one of those surveys, your responses go to the MR
          company (not to us), and will be governed by the MR company’s privacy
          policy. If you have any questions about this data sharing or if you
          want to opt-out, please contact privacy@ayetstudios.com. If you
          opt-out, it is the responsibility of us to inform the Third Party MR
          Companies. Please note that ayeT-Studios is solely liable and
          responsible for managing all opt-out requests. Your participation in
          such survey opportunities does not entitle you to any benefits offered
          by, or membership with, the Third Party MR Companies.
        </ul>
      </p>

      <h5>3. What does ayeT-Studios do with Personal Information?</h5>

      <p>
        ayeT-Studios uses the personal information that it collects and receives
      </p>
      <p>
        <ul>- to provide the Services;</ul>
        <ul>- to enable and optimize the Services’ tools and features</ul>
        <ul>
          - to study and analyze the functionality of the Services, Customers’
          and Users’ activities,
        </ul>
        <ul>- to provide support,</ul>
        <ul>- to measure the Services’ activity for pricing purposes,</ul>
        <ul>- to maintain the Services</ul>
        <ul>
          - to improve the Services and to continue developing the Services.
        </ul>
      </p>

      <p>
        ayeT-Studios may use the information collected to evaluate Users’
        interests and behavior on a pseudonymous, no-name basis in order to
        being able to deliver tailored content. If necessary, ayeT-Studios may
        use identifiable information to enforce its terms, policies and legal
        agreements, to comply with court orders and warrants, and assist law
        enforcement agencies, to collect debts, prevent fraud, misappropriation,
        infringements, identity thefts and any other misuse of the Services, and
        to take any action in any legal dispute and proceeding.
      </p>

      <p>
        ayeT-Studios commits to process personal information solely for the
        purposes described in this policy. To the extent relevant and possible,
        ayeT-Studios will make efforts to maintain accurate information that is
        complete and up-to-date.
      </p>

      <h5>4. Sharing Personal information with others</h5>

      <p>
        ayeT-Studios may share personal information for the following purposes:
      </p>

      <p>Share it with its trusted Demand Partners:</p>
      <p>
        <ul>
          - For the purpose of serving you with contextual and/or targeted ads
          that are relevant to you and may interest you.
        </ul>
        <ul>
          - For ad reporting purposes and for Demand Partners to have
          information about the performance of their ad campaigns and improve
          their ads performance over time.
        </ul>
      </p>

      <p>Share it with Supply Partners:</p>

      <p>
        -To help them understand how Users are engaging with ads, what kind of
        Users are most engaging with certain types of ads on their Property, and
        what kind of ads are published on their Property;
      </p>

      <p>Share it with Third Parties:</p>
      <p>
        <ul>
          - To receive additional data on you from sources other than the
          Property in order to help analyze and enrich the information collected
          on you, as well as serve you with more relevant and targeted ads on
          the Property and elsewhere.
        </ul>
        <ul>
          - To receive Third Party’s services, such as fraud protection, bot
          detection, rating, analytics, viewability, geo location services, ad
          security and verification services.
        </ul>
      </p>

      <p>
        Share it with ayeT-Studios’ corporate affiliates in order to provide the
        Services.
      </p>

      <p>
        Share it with law enforcement and/or government agencies, courts, and/or
        other organizations: (a) as required by law, such as to comply with a
        subpoena, or similar legal process; and/or (b) when ayeT-Studios
        believes in good faith that disclosure/sharing is necessary to protect
        its rights, protect your safety or the safety of others, investigate
        fraud, or respond to a government request;
      </p>

      <p>
        Share or transfer it, if ayeT-Studios is involved in a merger,
        acquisition, or sale of all or a portion of its assets, as part of such
        transaction. You will be notified via a prominent notice on
        ayeT-Studios’ website of any change in ownership or uses of this
        information. In any event, your information will remain protected under
        this Policy;
      </p>

      <p>
        Share it in any other circumstances where ayeT-Studios receives your
        written consent.
      </p>

      <h5>5. Aggregated and analytical information</h5>

      <p>
        ayeT-Studios may use standard analytics tools. The privacy practices of
        these tools are subject to their own privacy policies, and they may use
        their own cookies to provide their service (for further information
        about cookies, please see the ‘Cookies’ section in this policy).
      </p>

      <p>ayeT-Studios uses the standard analytics tools such as:</p>
      <p>
        <ul>- Google Analytics</ul>
        <ul>- Google Firebase</ul>
      </p>

      <p>and may use additional or other analytics tools, from time to time.</p>

      <p>
        The privacy practices of these tools are subject to their own privacy
        policies and can be found here:
      </p>

      <p>
        Google Analytics: http://www.google.com/analytics/learn/privacy.html
      </p>

      <p>Google Firebase: https://policies.google.com/privacy</p>

      <h5>6. Cookies</h5>

      <p>
        Cookies are packets of information sent to your web browser and then
        sent back by the browser each time it accesses the server that sent the
        cookie.
      </p>

      <p>
        ayeT-Studios uses session cookies for legitimate purposes, such as
        facilitation of the use of the Property you use and its features and
        tools. To clarify, ayeT-Studios’ cookies do not collect any personal
        information about you. When you visit a website, the cookie can
        recognize your device based on internal identifier of ayeT-Studios’
        systems and in the future, if you return to that website, it can read
        that cookie to remember you from your last visit. By keeping track of
        you over time, cookies may be used by ayeT-Studios and its Demand
        Partners Advertisers to deliver ads targeted to you and track your
        engagement with these ads.
      </p>

      <p>
        If you wish to delete cookies or limit the kinds of cookies that can be
        placed on your device you need to check out the settings in your
        browser, use the ‘Help’ tab or look for settings like ‘Options’ or
        ‘Privacy.’ From there, you may be able to delete cookies, block them or
        control when they can be placed.
      </p>

      <h5>7. User Choice</h5>

      <p>
        If you have an account in the Services, at any time, you may disable
        your account through your account page or by sending an opt-out or
        account deletion request to: privacy@ayetstudios.com. Thereafter,
        ayeT-Studios will stop collecting any personal information from you.
      </p>

      <p>
        However, ayeT-Studios may store and continue using or making available
        certain personal information that is related to you. For further
        information, please read the Data Retention section in this policy
      </p>

      <p>
        If you wish to opt-out from collection and use of precise location data
        for advertising, you can turn location services off through your device
        settings. The latest versions of iOS and Android allow you to limit
        which particular applications can access your location information.
      </p>

      <p>
        You also can limit the use of identifiers for ad targeting on your
        devices. If you turn on this setting, apps are not permitted to use the
        advertising identifier to serve consumers targeted ads.
      </p>

      <p>
        As an example, for iOS, the controls are available through Settings
        `&gt;` Privacy `&gt;` Advertising `&gt;` Limit Ad Tracking. For Android,
        Google Settings `&gt;` Ads `&gt;` Opt Out of Interest-Based Ads.
      </p>

      <p>
        Although this tool will limit the use of tracking data for targeting
        ads, companies may still be able to monitor your app usage for other
        purposes, such as research, measurement, and fraud prevention.
      </p>

      <p>
        You can reset the identifiers on your mobile device in the device
        settings. iOS users can do this by following Settings `&gt;` Privacy
        `&gt;` Advertising `&gt;` Reset Advertising Identifier. For Android, the
        path is Google settings `&gt;` Ads `&gt;` Reset advertising ID.
      </p>

      <p>
        Following such restart, the device is harder to associate with past
        activity, but tracking can start anew using the new advertising
        identifier.
      </p>

      <p>
        If you delete all cookies, you’ll also delete the cookies that indicate
        your preference to opt out of targeted ads. Please note that even if you
        opt out of targeted advertising, a company may still use cookies for
        other purposes, such as to limit the number of times you’re shown a
        particular ad.
      </p>

      <h5>8. Accessing Your Personal Information</h5>

      <p>
        At any time, you may contact us at: privacy@ayetstudios.com and request
        to access the personal information that ayeT-Studios keeps about you.
        ayeT-Studios may need to ask you to provide it with certain credentials
        to make sure that you are who you claim you are. If you find that the
        information on your account is not accurate, complete or updated, then
        please provide us the necessary information to correct it.
      </p>

      <p>
        At any time, you may contact ayeT-Studios at: privacy@ayeT-Studios.com
        and request to access the personal information that ayeT-Studios keeps
        about you, and to verify the accuracy of the data.
      </p>

      <p>
        If you’d like us to delete data that you have provided via the Property,
        please contact ayeT-Studios at: privacy@ayetstudios.com and ayeT-Studios
        will respond within a reasonable time after receiving your email.
      </p>

      <p>
        Please be advised that ayeT-Studios may retain and use your personal
        information as necessary to comply with its legal obligations, resolve
        disputes and enforce ayeT-Studios’ agreements.
      </p>

      <p>
        In addition, after ayeT-Studios deletes your personal information,
        residual copies may take a period of time before they are deleted from
        ayeT-Studios’ active servers and may remain in ayeT-Studios’ backup
        systems.
      </p>

      <p>
        ayeT-Studios may redact from the data which it will make available to
        you, any personal information related to others.
      </p>

      <h5>9. Your CCPA Data Subject Rights</h5>

      <p>
        Under the California Consumer Privacy Act of 2018 (CCPA), California
        residents have certain rights with respect to their personal
        information. These rights include the right to:
      </p>

      <p>
        Request access to your personal information collected, disclosed or
        sold:
      </p>
      <p>
        <ul>
          - You have the right to request that we provide you with details about
          the personal information we collect, use, disclose and sell.
          ayeT-Studios reserves the right to verify your identity to our
          satisfaction.
        </ul>
        <ul>- You are entitled to receive the following:</ul>
        <ul>
          a. The categories of your personal information that ayeT-Studios has
          collected in the preceding 12 months
        </ul>
        <ul>
          b. The categories of sources from which that information was collected
        </ul>
        <ul>
          c. The business/commercial purpose for the collection or selling
        </ul>
        <ul>
          d. The categories of third parties with whom ayeT-Studios shares
          personal information
        </ul>
        <ul>
          e. The specific pieces of personal information ayeT-Studios has
          collected about you (subject to some exceptions)
        </ul>
        <ul>
          - Because ayeT-Studios has disclosed or sold (as those words are
          defined in the CCPA) personal information to third parties in the last
          12 months, you are also entitled to receive:
        </ul>
        <ul>
          a. The categories of personal information that ayeT-Studios has
          disclosed or sold in the past 12 months
        </ul>
      </p>

      <p>Request deletion of your personal information:</p>
      <p>
        - You have the right to request that we delete the personal information
        we have collected about you.
      </p>

      <p>Opt out of the sale of your personal information:</p>
      <p>
        - You have the right to opt out of any potential future sale of your
        personal information.
      </p>

      <p>Non-discrimination:</p>
      <p>
        - You have the right not to be discriminated against for exercising your
        rights under the CCPA.
      </p>

      <p>
        To exercise your rights under the CCPA, please contact us at
        privacy@ayetstudios.com.
      </p>

      <h5>10. Your EU Data Subject Rights</h5>

      <p>
        ayeT-Studios is committed to assist its controllers as to data subjects’
        rights under the General Data Protection Regulation (Regulation (EU)
        2016/679) (GDPR).
      </p>

      <p>
        Please note that under the GDPR ayeT-Studios processes data provided
        into the Services as a processor and not as a controller (i.e. Supply
        Partners). As a processor, ayeT-Studios is committed to assist its
        controllers to fulfill data subjects’ GDPR rights.
      </p>

      <p>
        Therefore, if you wish to exercise your rights under the GDPR or have
        any questions in this matter, please contact the relevant ayeT-Studios’
        controller directly. When needed and upon request, ayeT-Studios will
        assist its controllers.
      </p>

      <p>
        ayeT-Studios conducts periodical assessments of its data processing and
        privacy practices to ensure that it complies with this Policy, update
        the Policy when necessary, and ensure that the policy is properly
        displayed and accessible.
      </p>

      <h5>11. Data Retention</h5>

      <p>
        As a general practice, ayeT-Studios stores your personal information for
        as long as you use the Property and for a 30 days thereafter.
        ayeT-Studios uses your personal information in these cases for internal
        reporting, analysis and to provide the Services. ayeT-Studios may save
        and use your personal information, in certain required cases, for a
        longer period of up to 90 days after you discontinue using the Property,
        if ayeT-Studios needs your personal information for invoicing,
        reporting, discrepancy investigation reasons.
      </p>

      <p>
        ayeT-Studios also uses and stores your personal information to prevent
        fraud. In certain cases, where ayeT-Studios suspects that the Property
        that you have used may be vulnerable to fraudulent activities,
        ayeT-Studios may store and use your personal information, if needed,
        after you discontinue using the Property for fraud detection purpose.
      </p>

      <p>
        Please note that ayeT-Studios may be required to retain certain
        information by law. Aggregated data may be retained by ayeT-Studios for
        longer periods and via a third-party analytics platform.
      </p>

      <p>
        ayeT-Studios will ensure that personal information processed for no
        longer than necessary for the purposes for which the personal
        information was provided.
      </p>

      <p>
        In any case, as long as ayeT-Studios uses your personal information to
        provide the Service, ayeT-Studios will keep the information about you,
        unless ayeT-Studios is required by law to delete it, or if ayeT-Studios
        accepts your request to delete the information, pursuant to applicable
        laws, or if ayeT-Studios decides to remove it at its discretion.
      </p>

      <p>
        As a Customer, as long as you use the Services, ayeT-Studios will keep
        information about you, unless it is required by law to delete it, or if
        ayeT-Studios decides to remove it at its discretion.
      </p>

      <h5>12. Transfer of data outside your territory</h5>

      <p>
        ayeT-Studios may store and process information in various sites
        throughout the globe, including in sites operated and maintained by
        cloud-based service providers.
      </p>

      <p>
        If you are a resident in a jurisdiction where transfer of your personal
        information to another jurisdiction requires your consent, then you
        provide ayeT-Studios your express and unambiguous consent to such
        transfer.
      </p>

      <p>
        If you are a resident of the European Economic Area (EEA), your personal
        data is processed and stored by Amazon Web Services (AWS) Cloud in the
        US on ayeT-Studios’ behalf in accordance with the highest degree of
        security of AWS.
      </p>

      <p>
        ayeT-Studios may implement additional onward transfer mechanisms, as may
        be available from time to time.
      </p>

      <h5>13. Information Security</h5>

      <p>
        ayeT-Studios is committed to safeguarding the confidentiality of your
        personal information. ayeT-Studios and its hosting services implement
        systems, applications and procedures to secure your personal
        information, to minimize the risks of theft, damage, loss of
        information, or unauthorized access or use of information.
      </p>

      <p>
        As an example, ayeT-Studios limits access to information to authorized
        personal on a need to know basis in order to operate, develop or improve
        the Services.
      </p>

      <p>
        While all measures provided reflect the current industry standard of
        security, ayeT-Studios cannot guarantee that the Services will be immune
        from any wrongdoings, malfunctions, unlawful interceptions or access, or
        other kinds of abuse and misuse.
      </p>

      <h5>14. Changes to this Privacy Policy</h5>

      <p>
        From time to time, ayeT-Studios may update this Policy. If the updates
        have minor if any consequences, they will take effect 7 days after
        ayeT-Studios posts a notice on the Services’ website. Substantial
        changes will be effective 30 days after ayeT-Studios’ notice was
        initially posted.
      </p>

      <p>
        Until the new policy takes effect, you can choose not to accept it and
        terminate your use of the Services. Continuing using the Services after
        the new policy takes effect means that you agree to the new policy.
      </p>

      <p>
        Note that if ayeT-Studios needs to adapt the policy to legal
        requirements, the new policy will become effective immediately or as
        required.
      </p>

      <h5>15. Contact Us</h5>

      <p>
        ayeT-Studios is using an external certified data protection officer. The
        data protection officer can be reached via the following contact
        details:
      </p>

      <p>activelaw Offenhausen.Wolter PartmbB</p>
      <p>Marion Albrecht</p>
      <p>Hans-Böckler-Allee 26</p>
      <p>D-30173 Hannover</p>
      <p>privacy@ayetstudios.com</p>

      <p>Please contact the data protection officer for further information.</p>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
